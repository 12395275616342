<template>
    <el-container>
        <el-main class="main-box">
            <!-- card面板 -->
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <!-- 头部标题与form表单 -->
                    <el-row :gutter="20">
                        <!-- span属性指的是占据多少列 -->
                        <el-col :span="6" style="padding: 0;">
                            <span>普通组汇总数据</span>
                        </el-col>
                    </el-row>
                </div>
                <div class="text item">

                    <el-table
                            stripe
                            :data="tableList1"
                    >
                        <el-table-column prop="groupId" label="组名" align="center"/>
                        <el-table-column prop="nowNum" label="当前任务数" align="center"/>
                        <el-table-column prop="processNum" label="处理中任务数" align="center"/>
                        <el-table-column prop="laterProcessNum" label="稍后处理任务数" align="center"/>
                        <el-table-column prop="processButUnsolvedNum" label="已处理未解决任务数" align="center"/>
                        <el-table-column prop="processAndSolvedNum" label="已处理已解决任务数" align="center"/>
                        <el-table-column prop="historyNum" label="历史处理任务数" align="center"/>
                        <el-table-column prop="notDistributeNum" label="未分配任务数" align="center"/>
                    </el-table>
                </div>
            </el-card>
            <!-- card面板 -->
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <!-- 头部标题与form表单 -->
                    <el-row :gutter="20">
                        <!-- span属性指的是占据多少列 -->
                        <el-col :span="6" style="padding: 0;">
                            <span>普通组各组汇总数据</span>
                        </el-col>
                    </el-row>
                </div>
                <div class="text item">

                    <el-table
                            stripe
                            :data="tableList2"
                    >
                        <el-table-column prop="groupId" label="组名" align="center"/>
                        <el-table-column prop="nowNum" label="当前任务数" align="center"/>
                        <el-table-column prop="processNum" label="处理中任务数" align="center"/>
                        <el-table-column prop="laterProcessNum" label="稍后处理任务数" align="center"/>
                        <el-table-column prop="processButUnsolvedNum" label="已处理未解决任务数" align="center"/>
                        <el-table-column prop="processAndSolvedNum" label="已处理已解决任务数" align="center"/>
                        <el-table-column prop="historyNum" label="历史处理任务数" align="center"/>
                    </el-table>
                </div>
            </el-card>
            <!-- card面板 -->
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <!-- 头部标题与form表单 -->
                    <el-row :gutter="20">
                        <!-- span属性指的是占据多少列 -->
                        <el-col :span="6" style="padding: 0;">
                            <span>普通组明细数据</span>
                        </el-col>
                    </el-row>
                </div>
                <div class="text item">

                    <el-table
                            stripe
                            :data="tableList"
                    >
                        <el-table-column prop="groupName" label="普通组"
                                         align="center"
                                         :filter-multiple="false"
                                         column-key="groupName"
                                         :filters="filterList"
                                         :filter-method="filterHandler"/>
                        <el-table-column prop="mobileName" label="机动人员姓名" align="center"/>
                        <el-table-column prop="mobilePhone" label="机动人员手机号" align="center"/>
                        <el-table-column prop="haveTask" label="是否有任务" align="center"
                                         :filters="[{
                                             text: '是',
                                             value: true,
                                         },{
                                             text: '否',
                                             value: false,
                                         }]"
                                         :filter-multiple="false"
                                         column-key="haveTask"
                                         :filter-method="filterHandler"
                        >
                            <template slot-scope="scope">
                                <span v-if="scope.row.haveTask">是</span>
                                <span v-else>否</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="processing" label="是否在处理" align="center"
                                         :filters="[{
                                             text: '是',
                                             value: true,
                                         },{
                                             text: '否',
                                             value: false,
                                         }]"
                                         :filter-multiple="false"
                                         column-key="processing"
                                         :filter-method="filterHandler"
                        >
                            <template slot-scope="scope">
                                <span v-if="scope.row.processing">是</span>
                                <span v-else>否</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="laterProcessNum" label="稍后处理任务数" align="center"/>
                        <el-table-column prop="processButUnsolvedNum" label="已处理未解决任务数" align="center"/>
                        <el-table-column prop="processAndSolvedNum" label="已处理已解决任务数" align="center"/>
                        <el-table-column prop="historyNum" label="历史处理任务数" align="center"/>
                    </el-table>

                    <!-- 分页器 -->
<!--                    <el-pagination-->
<!--                            background-->
<!--                            @size-change="handleSizeChange"-->
<!--                            @current-change="handleCurrentChange"-->
<!--                            :page-size="pageSize"-->
<!--                            :page-sizes="[10, 20, 30, 40]"-->
<!--                            :current-page="pageNum"-->
<!--                            layout="total, sizes, prev, pager, next, jumper"-->
<!--                            :total="total"-->
<!--                    >-->
<!--                    </el-pagination>-->
                </div>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>
    import {getAllDataByGroupType} from "r/jiDong/generalGroup.js";

    export default {
        name: "GeneralGroup",
        data() {
            return {
                isSelect: true,// true-待处理 false-已处理
                tableList1: [],
                tableList2: [],
                tableList3: [],
                tableList: [],
                filterList: [],
                queryParams: {
                    groupType: 'common'
                },
                filterParams: {
                    groupName: null,
                    haveTask: null,
                    processing: null
                },
                pageNum: 1,
                pageSize: 10,
                total: 0,
            }
        },
        created() {
            this.getList();
        },
        methods: {
            // 组名筛选
            filterChange(value) {
                console.log(value)
                for (let k in value) {
                    if (value[k].length > 0) {
                        this.filterParams[k] = value[k][0];
                    } else {
                        this.filterParams[k] = null;
                    }
                }
                this.tableList3 = this.getTable(1)
            },
            // 组名筛选
            filterHandler(value, row, column) {
                const property = column['property'];
                return row[property] === value;
            },
            // 获取列表数据
            getList() {
                getAllDataByGroupType(this.queryParams)
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            return this.$message.error(res.data.message);
                        }
                        this.tableList1 = [res.data.data.summaryData];
                        this.tableList2 = res.data.data.summaryDataOfGroup;
                        this.tableList = res.data.data.summaryDataOfTeacher;
                        this.total = this.tableList.length;
                        this.tableList3 = this.getTable();
                        this.filterList = this.getFilter();
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 获取组名筛选列表
            getFilter() {
                let arr = [];//去重后的数组
                let flag;
                for (let i in this.tableList) {
                    flag = true;
                    for (let j in arr) {
                        if (arr[j].text == this.tableList[i].groupName) {
                            flag = false;
                            break;
                        }
                    }
                    if (flag) {
                        arr.push({
                            text: this.tableList[i].groupName,
                            value: this.tableList[i].groupName,
                        });
                    }
                }
                return arr;
            },
            // 筛选第三个表格方法
            getTable() {
                let arr = [];
                arr = this.tableList.filter((item, index) => {
                    if (index >= (this.pageNum - 1) * this.pageSize
                        && index < (this.pageNum * this.pageSize)) {
                        return item;
                    }
                });
                return arr;
                // let arr2 = [];
                // if (val) {
                //     arr2 = this.tableList.filter((item, index) => {
                //         for (let k in this.filterParams) {
                //             if(this.filterParams[k]){
                //                 if (item[k] == this.filterParams[k]) {
                //                     return item;
                //                 }
                //             }else{
                //                 console.log(k)
                //                 return item;
                //             }
                //         }
                //     });
                //     console.log(arr2)
                //     arr = arr2.filter((item, index) => {
                //         if (index >= (this.pageNum - 1) * this.pageSize
                //             && index < (this.pageNum * this.pageSize)) {
                //             return item;
                //         }
                //     });
                //     this.total = arr.length;
                // } else {
                //     arr = this.tableList.filter((item, index) => {
                //         if (index >= (this.pageNum - 1) * this.pageSize
                //             && index < (this.pageNum * this.pageSize)) {
                //             return item;
                //         }
                //     });
                // }
                // return arr;
            },
            // 控制每页显示几条数据
            handleSizeChange(newSize) {
                this.pageSize = newSize;
                this.tableList3 = this.getTable();
            },
            // 控制显示第几页
            handleCurrentChange(newNum) {
                this.pageNum = newNum;
                this.tableList3 = this.getTable();
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "a/scss/jiDongCommon";
    @import "a/scss/table";
    @import "a/scss/page";

    ::v-deep .el-table__header-wrapper {
        height: auto !important;
    }
</style>
